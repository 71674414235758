.logInContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7F7F7;
}
.shadow {
    box-shadow: 0 2px 4px 2px #F7F7F7; 

}

.loginBox {
    gap: 20px;
    width: 25%;
    padding: 20px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 8px 2px #e2e2e2;
}

.logo {
    width: 80%;
    height: 250px;
    object-fit: contain;
}

.loginBox span {
    font-size: medium;
}


.iconContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.icon {
   font-size: 24px;
}

.largeIcon {
    font-size: 84px;
}

.inputContainer {
    height: 50px;
    display: flex;
    margin-top: 10px;
    border-radius: 10px;
    align-items: center;
    padding-left: 5px;
    background-color: #ffffff;
}

.inputContainer input {
    width: 90%;
    height: 100%;
    outline: none;
    border: none;
    /* margin: auto; */
    font-size: medium;
    background-color: none;
}

.buttonContainer {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.buttonContainer button {
    width: 50%;
    height: 50px;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: large;
    border-radius: 5px;
    background-color: #0E2346;
}

.buttonContainer button:hover {
    box-shadow: 0 4px 8px 2px #e2e2e2;
}
.error {
    font-size: smaller;
    color: firebrick;
}

.success {
    color: seagreen;
}

.pending {
    color: #F16822;
}

.popoverContainer {
    top: 0%;
    z-index: 333;
    width: 100vw;
    height: 100%;
    display: flex;
    /* padding: 20px 0px 20px 0px; */
    overflow-y: auto;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: #00000060;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    animation-name: FadeIn;
    animation-duration: 0.3s;
    animation-direction: normal;
}

.popoverBody {
    
    padding: 20px;
    display: flex;
    margin: auto;
    
    /* max-height: 0%; */
    flex-direction: column;
    border-radius: 10px;
    background: #e2e2e2;
    box-shadow: 0 4px 8px 2px grey;
    
}

@keyframes FadeIn {
    0% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}

.popoverWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popoverHeader {
    font-size: medium;
    border-bottom: 0.5px solid gray;
}

.popoverClose {
    top: -40px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.popoverClose div {
    width: 40px;
    height: 40px;
    color: red;
    display: flex;
    border-radius: 50%;
    align-items: center;
    background: #F7F7F7;
    justify-content: center;
}
.popoverClose div:hover {
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    box-shadow: 0 4px 8px 2px #00000060;

}

.txDetailsWrapper {
    width: 95%;
}

.txDetailsHeader {
    gap: 10px;
    display: flex;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.avatar, .avatarImage {
    width: 100px;
    height: 100px;
}
.avatar {
   
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 2px #F7F7F7; 
}

.userCardContainer {
    gap: 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.cardInfoContainer {
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.cardInfo {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.title {
    font-size: large;
    padding: 15px 0px 0px 0px;
}

.listContainer {
    display: flex;
    font-size: medium;
    padding: 15px 0px 0px 0px;
    justify-content: space-between;
}

.noRecord {
    gap: 10px;
    flex: 1;
    height: 100%;
    /* color: red; */
    display: flex;
    font-size: medium;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
}
.noRecord dt {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 0.5px solid red;
    animation-name: ErrorIn;
    animation-duration: 0.5s;
    animation-direction: normal;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

@keyframes ErrorIn {
    0% {
        height: 0%;
    }
    100% {
        height: auto;
    }
}

.downloadBtn {
    border: none;
    padding: 5px;
    color: #ffffff;
    border-radius: 5px;
    background: #0E2346;
}

.exportForm {
    width: 100%;
    padding: 20px 0px 0px 0px;
}
.exportFormBtn {
    gap: 20px;
    display: flex;
    margin-top: 20px;
    padding: 10px 0px 0px 0px;
    justify-content: flex-end;
    border-top: 0.5px solid gray;
}

.exportFormBtn button {
    height: 50px;
    width: 150px;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.exportFormBtn button:hover{
    box-shadow: 0 4px 4px 2px rgba(128, 128, 128, 0.527);

}

.searchList {
    
    margin-top: 20px;
    background-color: transparent;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}
.searchList ul ol{
    padding: 10px;
    font-size: medium;
    border-top: 0.5px solid #ededed;
    transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;

}

.searchList ul ol:hover{
    background: #F7F7F7;
}



/* loader */
.ldsroller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .ldsroller div {
    animation: ldsroller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .ldsroller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .ldsroller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .ldsroller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .ldsroller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .ldsroller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .ldsroller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .ldsroller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .ldsroller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .ldsroller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .ldsroller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .ldsroller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .ldsroller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .ldsroller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .ldsroller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .ldsroller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .ldsroller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .ldsroller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes ldsroller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.exportBtnContainer {

    gap: 10px;
    flex: 1;
    display: flex;
    justify-content: space-between;

}
.exportBtnContainer button {
    flex: 1;
    
}

.exportBtnContainer button:nth-child(2) {
    background: #F16822;
}

.searchContainer {
    top: 0;
    width: '100%';
    z-index: 22;
    position: sticky;
    display: flex;
    margin-top: 10;
    background: #ededed;
    align-items: center;
    justify-content: center;
}
.searchContainer div  {
    height: 50px;
    display: flex;
    /* width: '90%'; */
    border-radius: 10px;
    align-items: center;
    padding-left: 5px;
    /* background-color: #ffffff; */
}
.searchContainer div input {
    width: '100%';
    border: none;
    outline: none;
    padding: 10;
}

.limitContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.limitBtn {
    height: 50px;
    width: 100px;
    outline: none;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    align-items: center;
    background: #0E2346;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.limitBtn:hover {
    transform: scale(1.1);
}
