* {
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    display: none;
}

html, body {
    width: 100%;
    height: 100%;
    display: grid;
    overflow-x: hidden;
    font-family: Arial, Helvetica, sans-serif;
    
}
/* .appWrapper {
    border: 1px solid red;
} */
.appWrapper {
    width: 100%;
    display: flex;
    height: 100%;
    position: sticky;
    overflow: hidden;
    flex-direction: row;
}
.asideArea {
    width: 13%;
    position: relative;
    /* border: 1px solid red; */
    background-color: #fcfdff;
    box-shadow: 0 0 5px 2px #d5d6d8;
    transition: 0.3s all ease-in;
    animation-name: slideOut;
    animation-duration: 1s;
    animation-direction: normal;
}

@keyframes slideOut {
    0% {
        width: 0%;
    }
    100% {
        width: 13%;
    }
}

.asideAreaSlide {
    width: 3.5%;
    /* margin-left: -50px; */
    position: relative;
    background-color: #0E2346;
    transition: 0.3s all ease-in;
}
.sectionArea {
    width: 87%;
    background-color: #fcfdff;
    /* border: 1px solid black; */
}
.sectionAreaSlide {
    width: 96.5%;
    background-color: #fcfdff;
}
.asideAreaContent {
    gap: 50px;
    display: grid;
    padding: 17px 13px;
    /* border: 1px solid green; */
}
.asideAreaContentSlide {
    gap: 100px;
    display: grid;
    padding: 17px 13px;
}
.logo {
    gap: 20px;
    width: 100%;
    display: grid;
    position: relative;
    align-items: center;
    /* border: 1px solid red; */
    grid-template-columns: 6fr 1fr;
}
/* .logoWrap {
    width: 130px;
    border: 1px solid red;
} */
.logoWrap img {
    width: 100%;
}
.logoWrapSlide {
    display: none;
    transition: 0.3s all ease-in;
}
.toggleBtnActive {
    color: #fff
}
.navIconSlide {
    color: #fff;
    font-size: 25px;
    display: grid;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* margin-left: 20%; */
}
.toggleBtn {
    right: 0%;
    width: 50px;
    display: grid;
    font-size: 33px;
    cursor: pointer;
    color: #0E2346;
    position: absolute;
    /* border: 1px solid red; */
    justify-content: flex-end;
}
.toggleBtnSlide {
    right: 0%;
    width: 50px;
    display: grid;
    font-size: 33px;
    cursor: pointer;
    color: #0E2346;
    position: absolute;
    justify-content: center;
}
.toggleBtn :only-child {
    cursor: pointer;
}
.navOptionsWrap {
    gap: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.navOption {
    gap: 5px;
    display: grid;
    font-size: 15px;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    grid-template-columns: 1fr 7fr;
}
.navIcon {
    display: grid;
    font-size: 25px;
    color: #0E2346;
    align-items: center;
    /* border: 1px solid red; */
}
.navName {
    display: grid;
    color: #0E2346;
    align-items: center;
    /* border: 1px solid green; */
}
.navNameSlide {
    display: none;
}
.logOutBtn {
    left: 6%;
    width: 90%;
    bottom: 4%;
    padding: 10px;
    color: #fff;
    font-size: 17px;
    position: absolute;
    text-align: center;
    border-radius: 10px;
    background-color: #0E2346;
}
.logOutBtnSlide {
    display: none;
}
.logOutBtnIcon {
    left: 5%;
    width: 90%;
    bottom: 1.5%;
    padding: 10px;
    display: grid;
    color: #fff;
    display: grid;
    font-size: 30px;
    position: absolute;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: #0E2346;
}
.headerWrap {
    display: grid;
    color: #0E2346;
    padding: 17px 20px;
    background-color: #f6f9ff;
    grid-template-columns: 1fr 1fr;
    /* border: 1px solid red; */
}
.welcomeMsg {
    text-align: right;
}
.headerWrap span {
    color: #F16822
}
.transWrap {
    color: #0E2346;
    padding: 0px 20px;
    /* border: 1px solid red; */
}
.adminWrap {
    padding: 10px 20px;
}
.sortWrap {
    flex: 2;
    gap: 10px;
    display: flex;
    align-items: center;
}
.sortWrap input, select {
    flex: 1;
    outline: none;
    border: none;
    height: 50px;
    border-radius: 5px;
    font-size: medium;
    padding: 0px 10px 0px 10px;
    background: #F7F7F7;
}
.sortWrap span {
    font-size: 14px;
}

.sortWrap .spoolBtn {
    outline: none;
    border: none;
    height: 50px;
    color: #0E2346;
    display: flex;
    font-size: medium;
    border-radius: 10px;
    align-items: center;
    justify-self: center;
    background: #ededed;
    padding: 0px 10px 0px 10px;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}
.spoolBtn:hover {
    box-shadow: 0 4px 8px 2px #00000060;
}
.transCard {
    gap: 25px;
    display: grid;
    /* border: 1px solid red; */
    grid-template-columns: repeat(4, 1fr)
}
.card {
    gap: 10px;
    display: grid;
    padding: 18px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 2px #e2e2e2;
    /* border: 1px solid red; */
}
.transHead {
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
}
.transHead section {
    flex: 1;
}
.queryBtn {
    width: 25%;
    height: 50px;
    padding: 4px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: medium;
    background-color: #0E2346 ;
    /* box-shadow: 0 0 2px #0E2346; */
}
.cardRow1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardRow2 {
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    justify-content: space-between;
}
.detailsIcon {
    display: grid;
    font-size: 20px;
    align-items: center;
    justify-content: center;
}
.cardIcon {
    width: 20%;
    display: grid;
    font-size: 35px;
    align-items: center;
    justify-content: center;
}
.allTrans { color: #0E2346; transition: all 0.3s ease-in}
.completedTrans { color: #009200; transition: all 0.3s ease-in}
.pendingTrans { color: #F16822; transition: all 0.3s ease-in}
.canceledTrans { color: #FF0000; transition: all 0.3s ease-in}

.allTrans:hover {box-shadow: 0px 4px 4px #bfbfff; cursor: pointer;}
.completedTrans:hover {box-shadow: 0px 4px 4px #a5c9a5; cursor: pointer;}
.pendingTrans:hover {box-shadow: 0px 4px 4px #ffedca; cursor: pointer;}
.canceledTrans:hover {box-shadow: 0px 4px 4px #ffd1d1; cursor: pointer;}

.subsections {
    /* border: 1px solid red; */
    gap: 10px;
    display: flex;
    padding: 17px 20px;
}
.leftsub {
    height: 70vh;
    width: 97.5%;
    padding: 10px;
    /* position: relative; */
    /* border: 1px solid red; */
    border-radius: 10px;
    overflow-y: scroll;
    background-color: #fff;
}
.paginationWrap {
    bottom: 5px;
    left: 15%;
    width: 70%;
    display: grid;
    color: #0E2346;
    text-align: center;
    position: absolute;
    grid-template-columns: repeat(7, 1fr);
}
.paginationWrap div:nth-child(7) {
    border-top-right-radius: 18px;
}
.paginationWrap div:nth-child(1) {
    border-top-left-radius: 18px;
}

.paginationWrap div {
    height: 40px;
    display: flex;
    font-size: medium;
    align-items: center;
    justify-content: center;
    border: 1px solid #0E2346;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}
.paginationWrap input {
    border: none;
    outline: none;
    height: 100%;
}
.queryWrap {
    width: 2.5%;
    transition: all 0.3s ease-in;
}
.queryWrapSlide {
    width: 30%;
    position: relative;
    animation-name: wrapOut;
    animation-duration: 1.5s;
    animation-direction: normal;
    /* border: 1px solid red; */
}
@keyframes wrapOut {
    0% {
        width: 5%;
    }
    100% {
        width: 30%;
    }
}
.tableHead {
    width: 100%;
}

.tableHead thead {
    text-align: left;
}

.tableHead {
    font-size: medium;
    font-weight: 500;
}
.tableBody {
    width: 100%;
}
.tableBody tr:hover {
    color: #FFFF;
    cursor: pointer;
    background: #0E2346;
    transition: all ease-in-out 0.3s;
    -webkit-transition:  all ease-in-out 0.3s;
}

/* .trDecorationWhite {
    background: #ffffff;
} */
.trDecorationColor {
    background-color: #F7F7F7;
}

.tableBody tr {
    height: 30px;
}
.successful {
    color: #009200
}
.failed {
    color: #FF0000;
}
.pending {
    color: #F16722
}
.searchIconCancel, .searchIcon {
    display: flex;
    cursor: pointer;
    font-size: 30px;
    color: #0E2346;
    align-items: center;
    border-radius: 10px;
    justify-content: center; 
    /* background-color: #f6f9ff; */
}
.searchIconCancel {
    /* width: 30px;  */
    margin-top: 15%;
    right: 0;
    z-index: 10;
    /* position: absolute; */
}
.searchIPT2 {
    margin-bottom: 40px;
    position: relative;
}
.searchIPT input, select {
    width: 90%;
    border: none;
    outline: none;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    background-color: #0E2346;
}

.searchIPT .userInput {
    margin-top: 20px;
    background: none;
    border: 0.5px solid gray;
}
.searchIPT input::placeholder {
    color: #fff;
}
.sendSearch {
    top: 10%;
    right: 13%;
    border: none;
    display: grid;
    outline: none;
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.icon {
    /* color: #ededed; */
    font-size: 24px;
}